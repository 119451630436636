<div class="wrapper">
  <h2 i18n="@@Our contacts">Our contacts</h2>
  <div class="contacts" [ngClass]="{ 'hr-layout': titleName === 'hr' }">
    @for(person of (persons() | async); track person.uuid ){
      <div class="contact">
        <div class="photo border-custom small" [style.background-image]="'url(' + person.imageUrl + ')'"
          [style.borderColor]="person.color"></div>
        <div class="full-name">
          <h4>{{ person.name }}</h4>
        </div>
        <div class="description">
          <div class="position">
            <p class="p bold">{{ person.position }}</p>
          </div>
          <div class="email">
            <a href="mailto:{{ person.email }}" class="link">{{ person.email }}</a>
          </div>
        </div>
      </div>
    }
  </div>
</div>
