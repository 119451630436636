import { CommonModule } from '@angular/common';
import { Component, Input, input } from '@angular/core';
import { Observable } from 'rxjs';
import { Person } from 'src/app/interfaces/person.interface';
import { environment } from 'src/environments/environment';
import { ContactTitles } from 'src/app/interfaces/contact-titles.interface';

@Component({
  selector: 'app-our-contacts',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './our-contacts.component.html',
  styleUrl: './our-contacts.component.scss',
})
export class OurContactsComponent {
  public persons = input.required<Observable<Person[]>>();
  @Input() titleName!: string;

  public readonly url = environment.staticUrl;

  protected titles: ContactTitles = {
    sales: 'Our contacts',
    hr: 'Our Hiring team',
  };
}
